import React from "react";
import Typography from "@material-ui/core/Typography";
import { Card, CardContent } from "@material-ui/core";
import { Link } from "react-router-dom";

function Services() {
  const base = process.env.REACT_APP_BASE_API_URL_DISPLAY;

  return (
    <div>
      <Card>
        <CardContent>
          <Typography variant="h4">
            OSDU™ Data Platform Services Overview
          </Typography>
          <br />
          <Typography component="div">
            Your Preview Deployment includes each of the&nbsp;
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://community.opengroup.org/osdu/platform/system"
            >
              Data Platform Services
            </a>
            &nbsp;specified by the OSDU Standard, as well as any prerequisite
            Cloud Provider services needed to support&nbsp;
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://community.opengroup.org/osdu/documentation/-/wikis/Releases/R2.0/Services#the-essence-of-common-code"
            >
              Provider Technologies and Provider Operations
            </a>
            &nbsp;for each service.
            <br />
            <br />
            Each of the services below are accessible using your Preview
            Deployment's data platform URL:&nbsp;
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href={base.concat("/api/")}
            >
              {base.concat("/api/")}
            </a>
            . You can also review the list of specific&nbsp;
            <Link className="text-link" to="/Endpoints">
              data platform service endpoints
            </Link>
            &nbsp;in your Preview Deployment for each of the services below.
          </Typography>
          <br />
          <br />

          <Typography variant="h5">Authentication</Typography>
          <br />
          <Typography component="div">
            Authentication is carried out by both the OSDU Data Platform and the
            Cloud Provider (AWS) Services. The 47Lining Enterprise PaaS -
            Preview Deployment uses&nbsp;
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-authentication-flow.html"
            >
              AWS Cognito
            </a>
            &nbsp;for Authentication. It starts with the user passing in their
            credentials and authorization tokens are generated to use in all
            their future requests. Users can also copy the AccessToken to the
            clipboard in the Management Console provided by 47Lining.
            <br />
            <br />
          </Typography>

          <Typography variant="h5">Dataset</Typography>
          <br />
          <Typography component="div">
            The OSDU Dataset service allows users to register and retrieve
            datasets in the OSDU Data Platform.
            <br />
            <br />
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href={base + "/api/dataset/v1/swagger"}
            >
              Dataset API
            </a>
            &nbsp;and&nbsp;
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://community.opengroup.org/osdu/platform/system/dataset"
            >
              Dataset Documentation
            </a>
            <br />
            <br />
          </Typography>

          <Typography variant="h5">Entitlements</Typography>
          <br />
          <Typography component="div">
            The Entitlements service is used to enable authorization in Data
            Ecosystem. It relies on Cloud Provider groups as an infrastructure
            that ties into Cloud Provider IAM making AWS native authorization a
            possibility. The service allows for the creation of Cloud Provider
            groups. A group name defines a permission. Users who are added to
            that group obtain that permission. For each group users can either
            be added as an OWNER or a MEMBER. The only difference being if a
            user is an OWNER of a group, then they can manage the members of
            that group.
            <br />
            <br />
          </Typography>

          <Typography variant="h5">File</Typography>
          <br />
          <Typography component="div">
            The File service provides internal and external APIs to request for
            file location data, such as a signed URL per file upload. Using the
            signed URL, OSDU Data Platform users will be able to upload their
            files for ingestion to the system.
            <br />
            <br />
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href={base + "/api/file/v2/swagger"}
            >
              File API
            </a>
            &nbsp;and&nbsp;
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://community.opengroup.org/osdu/platform/system/file"
            >
              File Documentation
            </a>
            <br />
            <br />
          </Typography>

          <Typography variant="h5">Indexer</Typography>
          <br />
          <Typography component="div">
            The Indexer service, indexes the metadata store to support search.
            The indexer service will automatically take items that are newly
            added to storage and index the attributes from the schema associated
            with the kind attribute.
            <br />
            <br />
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href={base + "/api/indexer/v2/swagger"}
            >
              Indexer API
            </a>
            &nbsp;and&nbsp;
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://community.opengroup.org/osdu/platform/system/indexer-service/-/blob/master/docs/tutorial/IndexerService.md"
            >
              Indexer Documentation
            </a>
            <br />
            <br />
          </Typography>

          <Typography variant="h5">Legal</Typography>
          <br />
          <Typography component="div">
            Data compliance is largely governed through the Records in the
            storage service. Though there is an independent legal service and
            LegalTags entity, these offer no compliance by themselves. Records
            have a Legal section in their schema and this is where the
            compliance is enforced. However, clients must still make sure they
            are using the Record service correctly to remain compliant.
            <br />
            <br />
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href={base + "/api/legal/v1/swagger"}
            >
              Legal API
            </a>
            &nbsp;and&nbsp;
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://community.opengroup.org/osdu/platform/security-and-compliance/legal/-/blob/master/docs/tutorial/ComplianceService.md"
            >
              Legal Documentation
            </a>
            <br />
            <br />
          </Typography>

          <Typography variant="h5">Notification</Typography>
          <br />
          <Typography component="div">
            The Notification service, with Register service, allows interested
            consumers to subscribe to data and metadata changes using a
            publisher/subscriber pattern.
            <br />
            <br />
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href={base + "/api/notification/v1/swagger"}
            >
              Notification API
            </a>
            &nbsp;and&nbsp;
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://community.opengroup.org/osdu/platform/system/notification/-/blob/master/docs/tutorial/DataNotification.md"
            >
              Notification Documentation
            </a>
            <br />
            <br />
          </Typography>

          <Typography variant="h5">Register</Typography>
          <br />
          <Typography component="div">
            This service will allow an application to register an action (the
            function to be triggered). It will expect data (context) to come
            from the OSDU Data Platform to enable the action, and the
            application can register a filter (enable/disable) to say what data
            can be used with this action.
            <br />
            <br />
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href={base + "/api/register/v1/swagger"}
            >
              Register API
            </a>
            &nbsp;and&nbsp;
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://community.opengroup.org/osdu/platform/system/register/-/blob/master/docs/tutorial/ActionService.md"
            >
              Register Documentation
            </a>
            <br />
            <br />
          </Typography>

          <Typography variant="h5">Schema</Typography>
          <br />
          <Typography component="div">
            Schema Service enables a centralized governance and management of
            schema in the Data Ecosystem. It offers an implementation of the
            schema standard. Schema Service provides all necessary APIs to
            Fetch, create, update and mark a schema obsolete.
            <br />
            <br />
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href={base + "/api/schema-service/v1/swagger"}
            >
              Schema API
            </a>
            &nbsp;and&nbsp;
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://community.opengroup.org/osdu/platform/system/schema-service/-/blob/master/docs/SchemaService-OSDU.md"
            >
              Schema Documentation
            </a>
            <br />
            <br />
          </Typography>

          <Typography variant="h5">Search</Typography>
          <br />
          <Typography component="div">
            The Search API provides a mechanism for indexing documents that
            contain structured data. Users can search an index, and organize and
            present search results. Documents and indexes are saved in a
            separate persistent store optimized for search operations. The
            Search API can index any number of documents.
            <br />
            <br />
            The API supports full text search on string fields, range queries on
            date, numeric or string fields etc. along with geo-spatial search.
            <br />
            <br />
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href={base + "/api/search/v2/swagger"}
            >
              Search API
            </a>
            &nbsp;and&nbsp;
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://community.opengroup.org/osdu/platform/system/search-service/-/blob/master/docs/tutorial/SearchService.md"
            >
              Search Documentation
            </a>
            <br />
            <br />
          </Typography>

          <Typography variant="h5">Storage</Typography>
          <br />
          <Typography component="div">
            The Storage Service provides a set of APIs to manage the entire
            metadata life-cycle of ingesting metadata into the Data Ecosystem
            (persistence), modification, deletion, versioning and data schema.
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href={base + "/api/storage/v2/swagger"}
            >
              Storage
            </a>
            <br />
            <br />
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href={base + "/api/storage/v2/swagger"}
            >
              Storage API
            </a>
            &nbsp;and&nbsp;
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://community.opengroup.org/osdu/platform/system/storage/-/blob/master/docs/tutorial/StorageService.md"
            >
              Storage Documentation
            </a>
            <br />
            <br />
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}

export default Services;
