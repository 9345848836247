import Login from "./Login";
import Reset from "./Reset";
import HOME from "./home";
import EXPLORE_CURRENT_IMPLEMENTATION from "./explore_current_implementation";
import MEET_FUTURE_NEEDS from "./meet_future_needs";
import IN_PRODUCTION from "./in_production";
import MANAGEMENT_CONSOLE from "./management_console";
import SAMPLE_APP from "./sample-app"

export default [
    ...HOME,
    ...EXPLORE_CURRENT_IMPLEMENTATION,
    ...MEET_FUTURE_NEEDS,
    ...IN_PRODUCTION,
    ...MANAGEMENT_CONSOLE,
    ...SAMPLE_APP,
    {path: '/login', component: Login, exact: true},
    {path: '/r', component: Reset, exact: true}
]
