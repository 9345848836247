import Rig from "../../style/assets/Images/rig-2251648.png";
import B from "../../style/assets/Images/b.jpg";
import SolarPanel from "../../style/assets/Images/Home_Intro.png";
import Windmill from "../../style/assets/Images/Industry_Energy_Intro.png";
import A from "../../style/assets/Images/a.jpg";
import React from "react";
import Cards from "../../components/cards/Cards";
import SvgMenuHome from "../../style/assets/Icons/MenuHome";

const TITLE = "Home";
const URL = "/".concat(TITLE.replace(/\s+/g, ''));

const ITEMS = [
    {
        title: "Explore Current Implementation",
        description:
            <>
                Understand and explore what&apos;s included in your
                Preview Deployment of 47Lining Enterprise PaaS.
            </>
        ,
        image: <img src={Rig} alt='x'/>,
        path: '/ExploreCurrentImplementation'
    }, {
        title: "Meet Future Needs",
        description:
            <>
	        Understand OSDU&trade; Data Platform basics to set up
	        users, ingest your own data, and explore benefits
	        for your use cases.
            </>
        ,
        image: < img src={B} alt="x"/>,
        path: '/MeetFutureNeeds'
    }, {
        title: "Production Operations",
        description:
            <>
	        Learn about considerations for using the OSDU Data
	        Platform in an enterprise production operations
	        setting.
            </>
        ,
        image: <img src={SolarPanel} alt="x"/>,
        path: '/InProduction'
    },
    {
        title: "Management Console",
        description:
            <>
                Interact with data platform services using a console
                user interface without writing any code.
            </>
        ,
        image: < img src={Windmill} alt="x"/>,
        path: '/ManagementConsole'
    },
    {
        title: "Sample Application",
        description:
            <>
                Use the Sample Visualization Application published by
                the OSDU Forum to visualize wells on a map and in a 3D
                space, by using the OSDU Data Platform APIs.
            </>
        ,
        image: <img src={A} alt="x"/>,
        path: '/OSDUR2VisualizationApp'
    },
]

export default [
    {
        name: TITLE,
        path: URL,
        component: () => (<Cards items={ITEMS}/>),
        addToSideMenu: true,
        wrappedWithNavigation: true,
        exact: true,
        icon: <SvgMenuHome/>
    },
    ...ITEMS.filter(i => i.component).map(i => {
        return {
            path: i.path,
            component: i.component,
            wrappedWithNavigation: true,
            exact: true
        }
    })
]
