import React from "react";
import {SampleAppMainPage} from "./pages/main";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";



export default [
    {
        name: 'Visualization App',
        path: '/OSDUR2VisualizationApp',
        component: () => (<SampleAppMainPage/>),
        addToSideMenu: true,
        wrappedWithNavigation: true,
        exact: true,
        icon: <ImageSearchIcon/>
    }, 
]