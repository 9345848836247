import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Grid, MenuItem, Box, Typography, IconButton,ClickAwayListener,
  useTheme, useMediaQuery} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {MenuPopper} from '../../UI';

const useStyles = makeStyles(theme => ({
  popper: {
    backgroundColor: theme.palette.white,
    padding: theme.spacing(2),    
    borderRadius: '2px',
    zIndex: theme.zIndex.popper,
    boxShadow: `0px 6px 10px rgba(0, 0, 0, 0.14)`
  },
  menuWrapper: {
    display: 'flex',
    flexGrow: 1,
    alignContent: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(20),   
    paddingLeft: theme.spacing(4),
    color: theme.palette.white,
    borderTop: `1px solid ${theme.palette.grey2}`,
    [theme.breakpoints.up('lg')]: {
      paddingTop: 0,
      paddingBottom: 0,
      borderTopWidth: 0,
      display: 'flex',
      position: 'relative'
    }    
  },
  title: {
    color: theme.palette.white,
    flexGrow: 1,
    alignSelf: 'center',
    marginRight: theme.spacing(2)
  },
  icon: {
    alignSelf: 'center' 
  },
  iconButton: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  menuContainer: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('lg')] : {
      paddingLeft: 0,
      paddingBottom: 0
    }     
  },
  menuItem: {
    color: theme.palette.white,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),    
    [theme.breakpoints.up('lg')]: {
      color: '#30373E'
    }    
  }
}));
const HelpMenu = ({title ='Help', menuOptions, handleHelpMenuChange, ...rest}) => {
  const classes = useStyles();
  const theme  = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    anchorEl === null ? setAnchorEl(event.currentTarget) : setAnchorEl(null);
  };

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const handleClose = () =>  setAnchorEl(null);  
  const handleClickAway = () => setAnchorEl(null);  

  const handleMenuSelect = (menuHandler) => {
    menuHandler();
    handleClose();
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
     <Grid item container className={classes.menuWrapper}>    
      <Typography 
        variant="body2" 
        className={classes.title}
        children = {title} />
      <HelpOutlineIcon 
              variant='outlined' 
              className={classes.icon} />
      <IconButton 
          aria-controls="user-menu"
          color='inherit'
          aria-haspopup="true"
          className={classes.iconButton}
          onClick={handleClick}>
          { isDesktop ? <ArrowDropDownIcon /> : <ArrowRightIcon/>} 
      </IconButton>
      <MenuPopper anchorEl={anchorEl}
        handleClose = {handleClose} 
        placement = 'bottom-end'
        children = {
          <Box className = {classes.menuContainer}>
            {menuOptions.map(option => {
          return (
            <MenuItem
              className = {classes.menuItem}
              onClick={() => handleMenuSelect(option.action)}
              onChange={handleHelpMenuChange}
              dense key={option.name}
              divider={option.divider}
              disabled={option.disabled}>        
              {option.name}
            </MenuItem>
          )
        })}
          </Box>
      }    
      >
      </MenuPopper>    
    </Grid >  
    </ClickAwayListener>    
  );
}

export default HelpMenu;
HelpMenu.propTypes = {
  handleHelpMenuChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  menuOptions: PropTypes.array.isRequired
}
