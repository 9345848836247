import React, {useState} from "react";
import {Box, useMediaQuery, useTheme} from '@material-ui/core';
import {Footer, SideBar, TopBar, UserMenu, HelpMenu} from "./style/components";
import {useDispatch, useSelector} from 'react-redux';
import {logOut, selectUserData} from "./state/auth";
import {makeStyles} from "@material-ui/core/styles";
import pages from './pages'
import {Auth} from "aws-amplify";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    container: {
        paddingTop: theme.height.topBar + theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        backgroundColor: theme.palette.background.main,
        minHeight: '100vh',
        maxWidth: '100vw',
        width: '100%',
        alignItems: 'baseline',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflowX: 'hidden',
        [theme.breakpoints.up('lg')]: {
            marginLeft: theme.width.drawer
        }
    },

    content: {
        flexGrow: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    toolbar: theme.mixins.toolbar,
}));

export const withNavigation = BaseComponent => ({...props}) => {
    const userData = useSelector(selectUserData);
    const dispatch = useDispatch();
    const [openSidebar, setOpenSidebar] = useState(false);
    const theme = useTheme();
    const classes = useStyles();

    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const toggleSideBar = () => setOpenSidebar((prev) => !(prev));
    const handleSidebarClose = () => {
        setOpenSidebar(false);
    };

    const handleUserMenuChange = () => {
        console.log('handleUserMenuChange')
    };

    const openInNewTab = (url) => {
        var win = window.open(url, '_blank');
        win.focus();
    }

    const NBSP = String.fromCharCode(160);

    const PRODUCT_OVERVIEW_URL = "https://www.47lining.com/paas/products/products-audience/latest/preview/enterprise-paas-preview/";
    const PRODUCT_DOCUMENTATION_URL = "https://www.47lining.com/paas/docs/preview/preview-docs-audience/latest/";

    return (
        <React.Fragment>
            <div className={classes.root}>
                <TopBar
                    menuIcon={openSidebar ? <CloseIcon/> : <MenuIcon/>}
                    toggleSideBar={toggleSideBar}
                    helpMenu={
	                <HelpMenu
			    title={NBSP.repeat(12).concat("Help")}
			    menuOptions={[
                                      {
                                          name: 'Product Overview',
					  action: () => {
                                              openInNewTab(PRODUCT_OVERVIEW_URL);
                                          }
                                      },
                                      {
                                          name: 'Product Documentation',
					  action: () => {
                                              openInNewTab(PRODUCT_DOCUMENTATION_URL);
                                          }
                                      }
                                  ]}
	            />}
                    userMenu={
                        <UserMenu userName={userData?.username || ''}
                                  handleChange={handleUserMenuChange}
                                  menuOptions={[
                                      {
                                          name: 'Copy token', action: () => {
                                              Auth.currentSession().then(
                                                  x => {
                                                      navigator.clipboard.writeText('Bearer ' + x.getAccessToken().getJwtToken())
                                                  }
                                              )
                                          },
                                          icon: (<AssignmentOutlinedIcon/>)
                                      },
                                      {
                                          name: 'Sign out', action: () => {
                                              dispatch(logOut())
                                          },
                                          icon: (<ExitToAppOutlinedIcon/>)
                                      }
                                  ]}
                        />
                    }
                />
                <SideBar
                    pages={pages.filter(p => p.addToSideMenu)}
                    variant='persistent'
                    open={isDesktop ? true : openSidebar}
                    onClose={handleSidebarClose}
                    userMenu={
                        <UserMenu userName={userData?.username || ''}
                                  handleChange={handleUserMenuChange}
                                  menuOptions={[
                                        {
                                            name: 'Copy token', action: () => {
                                            Auth.currentSession().then(
                                                x => {
                                                    navigator.clipboard.writeText('Bearer ' + x.getAccessToken().getJwtToken())
                                                }
                                            )
                                        },
                                        icon: (<AssignmentOutlinedIcon/>)
                                    },
                                    {
                                        name: 'Sign out', action: () => {
                                            dispatch(logOut())
                                        },
                                        icon: (<ExitToAppOutlinedIcon/>)
                                    }
                                ]}
                        />
                    }
                />
                <Box variant='main' className={classes.container}>
                    <Box className={classes.content}>
                        <BaseComponent {...props}  />
                    </Box>
                    <Footer
                    />
                </Box>
            </div>
        </React.Fragment>
    );
}