import Rig from "../../style/assets/Images/rig-2251648.png";
import React from "react";
import Windmill from "../../style/assets/Images/Industry_Energy_Intro.png";
import Cards from "../../components/cards/Cards";
import CodeIcon from "@material-ui/icons/Code";
import Search from "./Search";

const TITLE = "Management Console";
const URL = "/".concat(TITLE.replace(/\s+/g, ''));
const DESCRIPTION = <>
        47Lining provides Search and Load pages to enable a
        lightweight way to start interacting with the data
        directly using the APIs without code.
    </>;


const ITEMS = [
    {
        title: "Search",
        description:
            <>
                Web interface that allows search through data that is already loaded into the system.
            </>
        ,
        image: <img src={Windmill} alt='x'/>,
        path: '/Search',
        component: Search
    },
    {
        title: "Load",
        description:
            <>
                Web interface that allows to load new data to the system.
            </>
        ,
        image: <img src={Rig} alt="x"/>,
        path: '/Load'
    }
]

export default [
    {
        name: TITLE,
        path: URL,
        component: () => (<Cards header={{'title': TITLE, 'description': DESCRIPTION}} items={ITEMS}/>),
        addToSideMenu: true,
        wrappedWithNavigation: true,
        exact: true,
        icon: <CodeIcon/>
    },
    ...ITEMS.filter(i => i.component).map(i => {
        return {
            path: i.path,
            component: i.component,
            wrappedWithNavigation: true,
            exact: true
        }
    })
]
