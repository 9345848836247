import React from "react";
import Typography from "@material-ui/core/Typography";
import { Card, CardContent } from "@material-ui/core";

export default function Endpoints() {
  const base = process.env.REACT_APP_BASE_API_URL_DISPLAY;

  return (
    <div>
      <Card>
        <CardContent>
          <Typography variant="h4">Your OSDU™ Service Endpoints</Typography>
          <br />

          <Typography component="div">
            The following information shows how you can access the OSDU service
            endpoints for this installation. They are all based upon the Preview
            Deployment URL for the Management Console as your {base}. Using the{" "}
            {base} you can build the following service endpoints.
          </Typography>
          <br />
          <br />

          <Typography variant="h5">Dataset</Typography>
          <br />
          <Typography component="div">
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href={`${base}/api/dataset/v1/registerDataset`}
            >
              {base}/api/dataset/v1
            </a>
          </Typography>
          <Typography component="div">
            The Dataset API supports registerDataset and other endpoints as
            defined{" "}
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://community.opengroup.org/osdu/platform/system/dataset/-/blob/master/docs/dataset.swagger.yaml"
            >
              here
            </a>
            .
          </Typography>
          <br />
          <br />

          <Typography variant="h5">Entitlements</Typography>
          <br />
          <Typography component="div">
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href={`${base}/api/legal/entitlements/v1/groups`}
            >
              {base}/api/entitlements/v1
            </a>
          </Typography>
          <Typography component="div">
            The Entitlements API supports records and other endpoints as defined{" "}
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://community.opengroup.org/osdu/documentation/-/wikis/Releases/R2.0/Services/Entitlements/Swagger"
            >
              here
            </a>
            .
          </Typography>
          <br />
          <br />

          <Typography variant="h5">File</Typography>
          <br />
          <Typography component="div">
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href={`${base}/api/file/v2/getLocation`}
            >
              {base}/api/file/v2
            </a>
          </Typography>
          <Typography component="div">
            The File API supports getLocation and other endpoints as defined{" "}
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://community.opengroup.org/osdu/platform/system/file/-/blob/master/docs/file-service_openapi.yaml"
            >
              here
            </a>
            .
          </Typography>
          <br />
          <br />

          <Typography variant="h5">Indexer</Typography>
          <br />
          <Typography component="div">
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href={`${base}/api/indexer/v2/reindex`}
            >
              {base}/api/indexer/v2
            </a>
          </Typography>
          <Typography component="div">
            The Indexer API supports reindex and other endpoints as defined{" "}
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://community.opengroup.org/osdu/platform/system/indexer-service/-/blob/master/docs/tutorial/IndexerService.md"
            >
              here
            </a>
            .
          </Typography>
          <br />
          <br />

          <Typography variant="h5">Legal</Typography>
          <br />
          <Typography component="div">
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href={`${base}/api/legal/v1/legaltags`}
            >
              {base}/api/legal/v1
            </a>
          </Typography>
          <Typography component="div">
            The Legal API supports records and other endpoints as defined{" "}
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://community.opengroup.org/osdu/platform/security-and-compliance/legal/-/blob/master/docs/api/compliance_openapi.yaml"
            >
              here
            </a>
            .
          </Typography>
          <br />
          <br />

          <Typography variant="h5">Notification</Typography>
          <br />
          <Typography component="div">
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href={`${base}/api/notification/v1/records-changed`}
            >
              {base}/api/notification/v1
            </a>
          </Typography>
          <Typography component="div">
            The Notification API supports records-changed endpoint as defined{" "}
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://community.opengroup.org/osdu/platform/system/notification/-/blob/master/docs/api/notification_openapi.yaml"
            >
              here
            </a>
            .
          </Typography>
          <br />
          <br />

          <Typography variant="h5">Register</Typography>
          <br />
          <Typography component="div">
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href={`${base}/api/register/v1/subscription`}
            >
              {base}/api/register/v1
            </a>
          </Typography>
          <Typography component="div">
            The Register API supports subscription and other endpoints as
            defined{" "}
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://community.opengroup.org/osdu/platform/system/register/-/blob/master/docs/api/register_openapi.yaml"
            >
              here
            </a>
            .
          </Typography>
          <br />
          <br />

          <Typography variant="h5">Schema</Typography>
          <br />
          <Typography component="div">
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href={`${base}/api/schema-service/v1/schema`}
            >
              {base}/api/notification/v1
            </a>
          </Typography>
          <Typography component="div">
            The Schema API supports schema management endpoints as defined{" "}
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://community.opengroup.org/osdu/platform/system/schema-service/-/blob/master/docs/api/schema.yaml"
            >
              here
            </a>
            .
          </Typography>
          <br />
          <br />

          <Typography variant="h5">Search</Typography>
          <br />
          <Typography component="div">
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href={`${base}/api/search/v2/query`}
            >
              {base}/api/search/v2
            </a>
          </Typography>
          <Typography component="div">
            The Search API supports records and other endpoints as defined{" "}
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://community.opengroup.org/osdu/platform/system/search-service/-/blob/master/docs/api/search_openapi.yaml"
            >
              here
            </a>
            .
          </Typography>
          <br />
          <br />

          <Typography variant="h5">Storage</Typography>
          <br />
          <Typography component="div">
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href={`${base}/api/storage/v2/records`}
            >
              {base}/api/storage/v2
            </a>
          </Typography>
          <Typography component="div">
            The Storage API supports records and other endpoints as defined{" "}
            <a
              className="text-link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://community.opengroup.org/osdu/platform/system/storage/-/blob/master/docs/api/storage_openapi.yaml"
            >
              here
            </a>
            .
          </Typography>
          <br />
          <br />
        </CardContent>
      </Card>
    </div>
  );
}
