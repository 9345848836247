import React from "react";
import Cards from "../../components/cards/Cards";
import AssignmentIcon from '@material-ui/icons/Assignment';
import AddUsers from "./AddUsers";
import LoadYourOwnData from "./LoadYourOwnData";
import ValidateBenefits from "./ValidateBenefits";
import {Link} from "react-router-dom";

const TITLE = "Meet Future Needs";
const URL = "/".concat(TITLE.replace(/\s+/g, ''));
const DESCRIPTION = <>
                Once you have fully explored the OSDU™ Data Platform
		capabilities delivered out-of-the-box, you can adapt
		the platform to meet your requirements. You can start
		by 
                <Link className="text-link" to="/SetupUsers"> adding new users </Link>
		and changing security to meet your corporate
		requirements. You can also
                <Link className="text-link" to="/LoadYourOwnData"> load your own data </Link>
                using the APIs. Once users and data are loaded, you
can better evaluate the OSDU Data Platform&apos;s ability
		to
		<Link className="text-link" to="/ValidateBenefits"> support your use cases </Link>
		.  If your organization would like to accelerate these
		efforts, you can take advantage of 47Lining solution
		prototyping professional services.
</>;

const ITEMS = [
    {
        title: "Add Users",
        description:
            <>
	        Add additional users to your Preview Deployment so
	        your team can collaborate on data loading, use case
	        prototyping, and validating benefits.
            </>
        ,
        path: '/AddUsers',
        component: AddUsers
    },
    {
        title: "Load Your Own Data",
        description:
            <>
                Understand how to prepare and load data to the data
                platform, load your own test data, and plan your data
                loading strategy.
            </>
        ,
        path: '/LoadYourOwnData',
        component: LoadYourOwnData
    },
    {
        title: "Validate Benefits",
        description:
            <>
	        Evaluate how your enterprise can benefit from an
	        integrated upstream data platform by considering your
	        specific use cases.
            </>
        ,
        path: '/ValidateBenefits',
        component: ValidateBenefits
    },
]

export default [
    {
        name: TITLE,
        path: URL,
        component: () => (<Cards header={{'title': TITLE, 'description': DESCRIPTION}} items={ITEMS}/>),
        addToSideMenu: true,
        wrappedWithNavigation: true,
        exact: true,
        icon: <AssignmentIcon/>
    },
    ...ITEMS.filter(i => i.component).map(i => {
        return {
            path: i.path,
            component: i.component,
            wrappedWithNavigation: true,
            exact: true
        }
    })
]
