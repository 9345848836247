import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Card, CardContent, Divider, Typography} from '@material-ui/core';

const useStyles = props => makeStyles( theme => ({
    card: {
	minHeight: '240px',
        height: '100%'
    },
    title: {
        fontWeight: 700,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    body: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
	minHeight: (( props.image?.props?.children?.type === "img") ?
		 '4em' : '7em')
    },
    divider: {
        borderTop: `2px solid ${theme.palette.secondary.main}`,
        width: '100%'
    },
    image: {
        width: '100%',
        '& img': {
	    objectFit: 'cover',
	    objectPosition: '50% 75%',
            maxWidth: '100%',
            width: '100%',
            height: '192px'
        }
    }
}) )

const ImageCard = (props) => {

    const classes = useStyles(props)();

    const ConditionalDivider = () => props.hideDivider ? <span></span> :
        <Divider className={classes.divider} variant='fullWidth'/>
    
    return (
        <Card className={classes.card}>
            <CardContent>
                <Box className={classes.image}>
                    {props.image}
                </Box>
                <Typography variant="h5" component="h2"
                            className={classes.title}
                            color='primary'>{props.title}
                </Typography>
                <Typography variant="body1" component="p"
                            className={classes.body}>
                    {props.description}
                </Typography>
                <ConditionalDivider/>
            </CardContent>
        </Card>
    )
}

export default ImageCard;
