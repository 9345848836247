import React from "react";
import ImageCard from "../../style/components/UI/Card/ImageCard";
import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {useHistory} from "react-router-dom";

const useStylesHeader = makeStyles(theme => (
    {
	container: {
	    paddingBottom: theme.spacing(4)
	},
	card: {
	    width: '100%'
	},
	title: {
            fontWeight: 700,
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2)
	},
	body: {
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(1),
	    height: 'auto'
	}
    }
));

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%'
    },
    clickable: {
        cursor: 'pointer'
    },
    divider: {
        borderTop: `2px solid ${theme.palette.secondary.main}`,
        width: '100%'
    }
}));


function Cards(props) {
    const classes = useStyles();
    const classesHeader = useStylesHeader();
    const history = useHistory();


    const onLinkClick = (i) => {
        if (i.path) {
            if (i.path.startsWith('http')) {
                const win = window.open(i.path, '_blank');
                win.focus();
            } else {
                history.push(i.path)
            }
        }
    }

    const clickClass = (i) => {
        if (i.path) {
            return classes.clickable
        } else {
            return {}
        }
    }

    const ConditionalHeader = () => props.header === undefined ? null :
        <div className={classesHeader.container}>
		    <Typography variant="h5" component="h2" className={classesHeader.title}>
		        {props.header.title}
		    </Typography>
		    <Typography variant="body1" component="p" className={classesHeader.body}>
		        {props.header.description}
                    </Typography>
        </div>

    return (
    <>
        <Grid container spacing={3} className={classes.container}>
	    <Grid item xs={12}><ConditionalHeader/></Grid>
        </Grid>
        <Grid container spacing={3}
              className={classes.container}
              alignItems="stretch">
            {props.items.map(i => {
                return (
                    <Grid item xs={12} md={12} lg={4}
                          key={i.title}>
                        <ImageCard
                            title={<div className={clickClass(i)} onClick={() => onLinkClick(i)}>{i.title}</div>}
                            description={i.description}
                            image={<div className={clickClass(i)} onClick={() => onLinkClick(i)}>{i.image}</div>}
                            hideDivider={i.hideDivider}
                        />
                    </Grid>
                )
            })}
        </Grid>
    </>
    )
}

export default Cards;
