import React from 'react';
import Typography from "@material-ui/core/Typography";
import {Card, CardContent} from "@material-ui/core";


export default function AWSIntegration() {

    return (
        <div>
            <Card>
                <CardContent>
                    <Typography variant="h4">
                        AWS Integration
                    </Typography>
                    <br/>

                    <Typography variant="h5">
                        Infrastructure
                    </Typography>
                    <br/>
                    <Typography component="div">
                        The 47Lining Enterprise PaaS provides the OSDU™ Data Platform deployed into a single AWS Account.
						All AWS services used will be owned and billed to that account. The OSDU Forum provides open source
						code that is then deployed to AWS and linked to the Cloud Provider specific services through a
						Service Provider Interface (SPI). In some cases the infrastructure is dedicated and persistent and
						in other cases it is serverless and/or ephemeral.
                    </Typography>
                    <br/>
                    <br/>

                    <Typography variant="h5">
                        Data
                    </Typography>
                    <br/>
                    <Typography component="div">
                        The data that is loaded into the OSDU Data Platform is saved in S3 and DynamoDB. users do not have direct
                        access to the data and/or AWS services to retrieve the data. Users must go through the OSDU
                        services to persist or retrieve data.
                    </Typography>
                    <br/>
                    <br/>

                    <Typography variant="h5">
                        Security
                    </Typography>
                    <br/>
                    <Typography component="div">
                        In order to secure the OSDU Data Platform, the entitlements service is used for user and group management.
                        Using this service users can be granted access to the API endpoints allowing for certain actions
                        to be undertaken by users of different types. The entitlements service can also be used to grant
                        access to datasets based on a prescribed set of parameters such as geographic region.
                    </Typography>
                    <br/>
                    <br/>

                    <Typography variant="h5">
                        Cost
                    </Typography>
                    <br/>
                    <Typography component="div">
                        47Lining Enterprise PaaS provides the OSDU Data Platform evaluation instance for less than $1 monthly.
						This means that the deployment and use of the OSDU R2 services can take place at minimal cost beyond
						the cost of the underlying AWS services. Those AWS services will be billed as normal on each Customer’s AWS
                        invoice.
                    </Typography>
                </CardContent>
            </Card>
        </div>
    )
}
