import React from 'react';
import Typography from "@material-ui/core/Typography";
import {Card, CardContent} from "@material-ui/core";


export default function Dashboard() {

    return (
        <div>
            <Card>
                <CardContent>
                    <Typography variant="h4">
                        “OSDU™” Workload CloudWatch Dashboard
                    </Typography>
                    <br/>
                    <Typography component="div">
                   Your 47Lining Enterprise PaaS - Preview Deployment includes a
                   CloudWatch Dashboard that you can use to visualize all the metrics
                   and statistics related to your OSDU cloud resources. This dashboard will
                   help you to assess health of your OSDU cloud resources and get peak
                   insights and trend.
                    </Typography>
                    <br/>
                    <br/>

                    <Typography variant="h5">
                        Information about CloudWatch Dashboard
                    </Typography>
                    <br/>
                    <Typography component="div">
                     Steps to access CloudWatch Dashboard
                        <ul>
                            <li>You need to login into your AWS account where the OSDU instance is deployed.</li>
                            <li>Once you are logged into the account, select CloudWatch service
                            </li>
                            <li>Select Dashboards and click on OSDUWorkload</li>

                        </ul>
                        <ul>
                          OSDU Operations dashboard should be displayed. This dashboard contains information
                         about dataset, infrastructure, and users of the OSDU instance
                         </ul>
                           <ul>DataSet
                                <li>This section presents the metrics describing the OSDU instance dataset details.
                               It calculates and displays the information about documents loaded successfully and
                              failures. It also displays the information trend and count of indexer queue.</li>
                            </ul>
                            <ul>Users
                                <li>This section presents the metrics related to users of OSDU Instance. It displays
                                the count of sign-in attempts into the OSDU Console.</li>
                            </ul>
                            <ul>Infrastructure
                                <li>This section presents the metrics describing the OSDU instance cloud resources.
                                 It displays information about the usage count for each service,
                                 ElasticSearch http response codes, and ECS CPU and memory utilization for each of the OSDU services</li>
                            </ul>

                   </Typography>
                </CardContent>
            </Card>
        </div>
    )
}