import {Card, CardContent} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";

export default function UserSetupAndIntegration() {
    return (
        <div>
            <Card>
                <CardContent>
                    <Typography variant="h4">
                        Add New Users to Your Preview Deployment
                    </Typography>
                    <br/>
                    <Typography component="div">
                        User management is conducted via the AWS Cognito instance in each Customer’s AWS Account. The
                        current steps for adding new users is a manual process. Thus to add/modify/delete users please
                        submit a support ticket to 47Lining and a 47Lining support agent will make the change the
                        Customer’s behalf. Once users are added to Cognito they must be added to the OSDU™ Data Platform
						for Authorization on what data they are allowed to see. For that, 47Lining uses the <a className="text-link"
                                                                                             rel="noopener noreferrer"
                                                                                             target="_blank"
                                                                                             href="https://community.opengroup.org/osdu/documentation/-/wikis/Releases/R2.0/Services/Entitlements/Tutorial">Entitlements</a> service.
                        After that has been accomplished, every time a user logs in, they are automatically assigned the
                        appropriate entitlements defined for them as part of their entitlements setup.
                    </Typography>

                </CardContent>
            </Card>
        </div>
    )
}
