import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import {useSelector} from "react-redux";
import {selectFooterLinks} from "../../../state/shared";

const useStyles = makeStyles(theme => ({
    footer: {
        alignSelf: 'flex-end',
        marginTop: theme.spacing(2)
    },
    footerLink: {
        marginLeft: '20px',
        fontSize: '12px',
        letterSpacing: '0.22px',
        lineHeight: '20px'
    },
    footerAbout: {
        marginLeft: '20px',
        fontSize: '12px',
        letterSpacing: '0.22px',
        lineHeight: '20px',
        "&:hover": {
            cursor: 'pointer'
      },
    },
    copyrightText: {
        marginLeft: '20px',
        fontSize: '12px',
        letterSpacing: '0.22px',
        lineHeight: '20px'
    }
}));

const tag_version = document.querySelector('meta[name="version-build"]').content

const Footer = (props) => {
    const classes = useStyles();
    const footerLinks = useSelector(selectFooterLinks);

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
        <Grid container className={classes.footer}>
            <Grid item>
                {footerLinks.map(footerLink => {
                    return (
                        <Link
                            target={'_blank'}
                            href={footerLink.path}
                            className={classes.footerLink}
                            key={footerLink.text}
                        >
                            {footerLink.text}
                        </Link>
                    );
                })}
                <Link target={'_blank'} className={classes.footerAbout} onClick={handleClickOpen}>
                  About
                </Link>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">{"About"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                          Version: {tag_version}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                          Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
            <Grid item>
                <Typography className={classes.copyrightText} gutterBottom>
                    {String.fromCharCode(169) + 'Hitachi Vantara LLC 2021. All Rights Reserved. OSDU is a trademark of The Open Group.'}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default Footer;