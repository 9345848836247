import React from 'react';
import Typography from "@material-ui/core/Typography";
import {Card, CardContent} from "@material-ui/core";


export default function OSDUTutorialsSampleApplication() {

    return (
        <div>
            <Card>
                <CardContent>
                    <Typography variant="h4">
                        OSDU™ Tutorials Sample Application
                    </Typography>
                    <br/>
                    <Typography component="div">

    The OSDU Forum created a tutorial application that demonstrates
    many of the services acting on the test datasets. 47Lining has
    deployed that tutorial application and connected it to this
    environment for exploration. The web application allows for search
    and visualization of Wells datasets. It is a good sample for how
    to authenticate to the platform, interact with the apis and make
    an application out of OSDU Data Platform data.

                    </Typography>
                    <br/>
                    <br/>
                  
                    <Typography variant="h5">
                        OSDU Forum Reference
                    </Typography>
                    <br/>
                    <Typography component="div">
                        <ul>
                            <li><a className='text-link' rel="noopener noreferrer" target="_blank"
                                   href="https://community.opengroup.org/osdu/tutorials"> OSDU Data Platform Tutorial Application </a>
                            </li>
                            <li><a className='text-link' rel="noopener noreferrer" target="_blank"
                                   href="https://community.opengroup.org/osdu/tutorials/guides/-/blob/master/OSDU_Release2_Application_Development_Guide.pdf"> OSDU
                                Application Developer Guide </a></li>
                        </ul>
                    </Typography>
                </CardContent>
            </Card>
        </div>
    )
}