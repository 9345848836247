import {AmplifyAuthenticator, AmplifyContainer, AmplifySignIn} from "@aws-amplify/ui-react";
import React from "react";
import {I18n} from "aws-amplify";
import {Translations} from "@aws-amplify/ui-components";
import {Footer} from "../style/components";

function Login() {


    // revert hack from reset
    I18n.putVocabulariesForLanguage("en-US", {
        [Translations.BACK_TO_SIGN_IN]: "Back to Sign In",
    });

    return (
        <div>
            <AmplifyContainer>
                <AmplifyAuthenticator id={'amplify_root'} usernameAlias={'email'}>
                    <AmplifySignIn headerText="Sign in to the OSDU™ Data Platform" slot="sign-in">
                        <div slot="secondary-footer-content"></div>
                    </AmplifySignIn>
                </AmplifyAuthenticator>
            </AmplifyContainer>
            <Footer/>
        </div>

    );
}

export default Login;